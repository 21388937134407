import React from 'react'
import AppConfigContext from '../../reactContexts/AppConfigContext.js'

const ClientSideApp = React.lazy(() =>
  import('../../refine/LoadableRefineApp')
)

// this page is accessible throw: http://localhost:8000/editor/d
//  @TODO: check for the "strict url": http://localhost:8000/editor
export default function rdfxEditorAdmin(props) {

  const isSSR = typeof window === "undefined"

  // Close the main menu and init a "client only" Refine instance
  return (
    <AppConfigContext.Consumer>{
      ({ menu }) => {
        menu.handleClose()
        return !isSSR && (
          <React.Suspense fallback={<div> Editor is loading...</div>}>
            <ClientSideApp {...props} />
          </React.Suspense>
        )
      }

    }</AppConfigContext.Consumer>
  )
}
